














































































import { Component, Prop } from "vue-property-decorator";
import { BaseForm } from "./BaseForm";

@Component
export default class ForgotPasswordForm extends BaseForm {
  public labels: Record<string, string> = {
    email: "Email",
  };

  @Prop({ required: true })
  public signinUrl!: string;

  public model: Record<string, any> = {
    email: null,
  };

  public async send() {
    this.$alert.clear();
    this.$alert.addAlert({
      type: "success",
      message: "Form submitted",
    });
    this.$emit("done", {});
  }
}
